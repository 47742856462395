import { ShopOutlined } from '@ant-design/icons';
import FormCard from '@Components/FormCard';
import StoreDetail from '@Forms/StoreDetail';
import { EditedStore, NewStore } from '@Types/Store';
import StoresService from '@Services/Api/Stores';
import { useNavigate, useParams } from 'react-router-dom';
import { apiCall } from '@Utils';
import { NewBoard } from '@Types/Board';

const AddStore = () => {
  const { brandId } = useParams();
  let navigate = useNavigate();

  const handleSubmit = async (newStore: NewStore | EditedStore) => {
    const store = { ...newStore, BrandId: parseInt(brandId!) };

    try {
      const storeID = await apiCall(() => addStore(store), 'newStore');
      if (store.CardName) {
        const newBoard: NewBoard = {
          CodeVersionId: store.CodeVersionId,
          BoardBrandModelId: store.BoardBrandModelId,
          Count: store.Count,
          CardName: store.CardName,
          CreateCamera: store.CreateCamera,
          IsHasWifiDongle: store.IsHasWifiDongle,
          IsHasBluetoothDongle: store.IsHasBluetoothDongle,
          IsHasPoeSplitter: store.IsHasPoeSplitter,
          IsHasRaySpot: store.IsHasRaySpot,
          IsHasHelperBoard: store.IsHasHelperBoard,
        };
        await apiCall(() => addBoard(newBoard, storeID), 'newStore');
      }
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const addStore = async (newStore: NewStore) => {
    return await new StoresService().AddNewStore(newStore);
  };

  const addBoard = async (board: NewBoard, storeID: number) => {
    return await new StoresService().AddNewBoard(board, storeID);
  };

  return (
    <FormCard
      titleIcon={<ShopOutlined />}
      titleKey="addStore"
      className="store-detail-wrapper"
    >
      <StoreDetail onFinish={handleSubmit} />
    </FormCard>
  );
};

export default AddStore;
