import { FormProps, withTheme } from '@rjsf/core';
import { Theme as AntDTheme } from '@rjsf/antd';
import { Button } from 'antd';
const AntForm = withTheme(AntDTheme);
interface Props<T> extends FormProps<T> {}

function Form<T>({ ...props }: Props<T>) {
  return (
    <div className="json-schema-form">
      <AntForm
        {...props}
        children={true} // This prop hides the submit button
      />
    </div>
  );
}

export default Form;
