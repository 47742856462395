import BrandCard from '@Cards/BrandCard';
import { BrandActionButtonEnums } from '@Cards/BrandCard/BrandActionButtons';
import { FirstItemText } from '@Components/Card/FirstItemText';
import CardGridWrapper from '@Components/CardGridWrapper';
import { useHomepageContext } from '@Features/homepage/hooks';
import { getAllBrandsWithoutPagination } from '@Store/Brand/action';
import { useAppSelector } from '@Store/hooks';
import { Input, Row, Tag } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { RefreshButton } from '../RefreshButton';
import {
  BRAND_QUERY_PARAM,
  STORE_QUERY_PARAM,
} from '@Features/homepage/homepage.constants';
import { scrollTo } from '@Utils/helpers';
import Status from '@Enums/Status';

export function BrandSelector() {
  const brands = useAppSelector(s => s.Brand.allBrands);

  const {
    groupId,
    brandId,
    brandSearchValue,
    setBrandSearchValue,
    setStoreSearchValue,
    setBoardSearchValue,
    setCameraSearchValue,
    setBrandId,
    setStoreId,
    setQueryParam,
    deleteQueryParam,
  } = useHomepageContext();

  const { t } = useTranslation();

  const selectedBrandRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    getBrands();
  }, []);

  useEffect(() => {
    if (brands.status === Status.success) {
      scrollTo(selectedBrandRef);
    }
  }, [brands.status]);

  function getBrands() {
    dispatch(getAllBrandsWithoutPagination());
  }

  function searchChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    setBrandSearchValue(e.target.value);
    setStoreSearchValue('');
    setCameraSearchValue('');
    setBoardSearchValue('');
  }

  function brandChangeHandler(newBrandId: number) {
    setBrandId(newBrandId);
    setQueryParam(BRAND_QUERY_PARAM, newBrandId);

    setStoreId(undefined);
    deleteQueryParam(STORE_QUERY_PARAM);

    setBrandSearchValue('');
    setStoreSearchValue('');
    setCameraSearchValue('');
    setBoardSearchValue('');
  }

  const filteredBrands = brands?.data?.Data?.filter(
    brand =>
      (groupId === undefined ? true : groupId === brand.GroupId) &&
      brand.Name.toLocaleLowerCase().includes(
        brandSearchValue.toLocaleLowerCase()
      )
  );

  const selectedBrand = brands?.data?.Data?.find(brand => brandId === brand.Id);

  return (
    <div className="homepage-entity-selector-container">
      <Row justify="space-between" align="middle" wrap>
        <Title level={5} className="selector-title">
          {t('brand')}
        </Title>
        {selectedBrand && (
          <Tag color="magenta" className="selector-tag">
            {selectedBrand.Name}
          </Tag>
        )}
      </Row>

      <div className="store-selector-row">
        <Input
          onChange={searchChangeHandler}
          value={brandSearchValue}
          placeholder={t('searchBrandNameAndIdPlaceholder')}
        />
        <RefreshButton onClick={getBrands} buttonProps={{ size: 'middle' }} />
      </div>

      <CardGridWrapper
        cards={filteredBrands?.map(brand => (
          <div
            ref={brand.Id === selectedBrand?.Id ? selectedBrandRef : undefined}
            key={brand.Id}
          >
            <BrandCard
              brand={brand}
              onClick={() => brandChangeHandler(brand.Id)}
              firstItem={<FirstItemText text={brand.Id + ' - ' + brand.Name} />}
              isSelected={selectedBrand?.Id === brand.Id}
              hiddenButtons={[BrandActionButtonEnums.ALL]}
            />
          </div>
        ))}
        noData={filteredBrands?.length === 0}
        status={brands.status}
        error={brands.ErrorMessage}
        containerClassName="brand-selector-container homepage-entity-selector"
      />
    </div>
  );
}
