import GoBackButton from '@Components/GoBackButton';
import {
  ConfigBase,
  ConfigPageParams,
  ConfigSchemaWithoutId,
} from '@Types/Config';
import {
  Anchor,
  Button,
  Col,
  Divider,
  Input,
  Row,
  Space,
  Typography,
} from 'antd';
import { JSONSchema7 } from 'json-schema';
import { useState } from 'react';
import { OnChange } from '@monaco-editor/react';
import Form from '@Components/JsonSchema/Form';
import JsonSchema from '@Components/JsonSchema/JsonSchema';
import TextArea from 'antd/lib/input/TextArea';
import { useParams } from 'react-router-dom';
import { convertConfigTypeToEnum } from '@Utils/Config';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;
interface Props {
  schema?: ConfigBase;
  onSubmit: (schema: ConfigSchemaWithoutId) => void;
}

/**
 * @description This component is used for both adding/editing for config schemas
 *              The only difference is "editing" page gets a schema object as a prop so that the forms can be filled with existing data.
 */
export default function ConfigDetail({ schema, onSubmit }: Props) {
  const [jsonSchema, setJsonSchema] = useState<JSONSchema7>(
    schema ? JSON.parse(schema.Schema) : defaultConfigSchema
  );
  const [uiSchema, setUiSchema] = useState<JSONSchema7>(
    schema
      ? JSON.parse(schema.UISchema ?? JSON.stringify(defaultUiSchema))
      : defaultUiSchema
  );
  const [description, setDescription] = useState<string>(
    schema?.Description || ''
  );
  const [name, setName] = useState<string>(schema?.Name || '');

  const { entity } = useParams() as ConfigPageParams;
  const { t } = useTranslation();

  const jsonSchemaOnChange: OnChange = schema => {
    try {
      if (schema) setJsonSchema(JSON.parse(schema));
    } catch (err) {}
  };

  const uiSchemaOnChange: OnChange = schema => {
    try {
      if (schema) setUiSchema(JSON.parse(schema));
    } catch (err) {}
  };

  const submitHandler = () => {
    const submittedSchema: ConfigSchemaWithoutId = {
      ConfigSchemaType: convertConfigTypeToEnum(entity!),
      Name: name,
      Schema: JSON.stringify(jsonSchema),
      UISchema: JSON.stringify(uiSchema),
      Description: description,
    };

    onSubmit(submittedSchema);
  };

  return (
    <Col>
      <Space direction="horizontal">
        <GoBackButton />
        <Anchor>
          <Anchor.Link
            href="http://json-schema.org/learn/getting-started-step-by-step.html "
            title={t('documentation')}
          />
          <Anchor.Link
            href="https://rjsf-team.github.io/react-jsonschema-form/docs/version-4.2.3/quickstart"
            title={t('uiLibrary')}
          />
        </Anchor>
      </Space>
      <Divider />
      <Row gutter={[24, 0]}>
        <Col sm={24} md={9} lg={9}>
          <Title level={5}>JSON Schema</Title>
          <JsonSchema
            height="600px"
            value={JSON.stringify(jsonSchema, null, 2)}
            onChange={jsonSchemaOnChange}
          />
        </Col>

        <Col sm={24} md={9} lg={9}>
          <Title level={5}>UI Schema</Title>
          <JsonSchema
            height="600px"
            defaultValue={JSON.stringify(uiSchema, null, 2)}
            onChange={uiSchemaOnChange}
          />
        </Col>

        <Col
          sm={24}
          md={6}
          lg={6}
          style={{ maxHeight: 600, overflowY: 'auto' }}
        >
          <Title level={4}>İsim</Title>
          <Input onChange={e => setName(e.target.value)} value={name} />
          <Divider />
          <Title level={4}>Açıklama</Title>
          <TextArea
            onChange={e => setDescription(e.target.value)}
            value={description}
          />
          <Divider />
          <Form schema={jsonSchema} uiSchema={uiSchema} />
        </Col>
        <Button
          style={{ marginLeft: 'auto', marginTop: 4 }}
          onClick={submitHandler}
        >
          {t('submit')}
        </Button>
      </Row>
    </Col>
  );
}

const defaultConfigSchema: JSONSchema7 = {
  title: 'A registration form',
  description: 'A simple form example.',
  type: 'object',
  required: ['firstName', 'lastName'],
  properties: {
    firstName: {
      type: 'string',
      title: 'First name',
      default: 'Chuck',
    },
    lastName: {
      type: 'string',
      title: 'Last name',
    },
    telephone: {
      type: 'string',
      title: 'Telephone',
      minLength: 10,
    },
  },
};

const defaultUiSchema: JSONSchema7 = {};
