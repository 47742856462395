import { Tag } from 'antd';
import './style.scss';

type Props = {
  itemCount: number;
  passiveItemCount: number;
  activeItemTitle: string;
  passiveItemTitle: string;
};

/*
    This component is used to display the passive item count of an entity (board etc.) as a badge.
*/
export default function PassiveCount({
  itemCount,
  passiveItemCount,
  activeItemTitle,
  passiveItemTitle,
}: Props) {
  return (
    <div className="passive-count">
      {passiveItemCount !== 0 && (
        <Tag color="red" title={passiveItemTitle}>
          {passiveItemCount}
        </Tag>
      )}
      <Tag color="green" title={activeItemTitle}>
        {itemCount - passiveItemCount}
      </Tag>
    </div>
  );
}
