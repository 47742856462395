import Card, { CardProps } from '@Components/Card';
import './style.scss';
import { FetchedStore } from '@Types/Store';
import HierarchyItems from '@Components/Card/HierarchyItems';
import { Permissions } from '@Enums/Permission';
import useCheckPermission from '@Hooks/useCheckPermission';
import PassiveCount from '@Components/PassiveCount';
import { useTranslation } from 'react-i18next';
import StoreActionButtons, { StoreActionButtonEnums } from './StoreActionButtons';
import { convertApiDate } from '@Utils/index';
import CardInfo, { CardInfoProps } from '@Components/Card/CardInfo';
import { InfoOutlined, FolderOpenOutlined, WifiOutlined } from '@ant-design/icons';
import { useMemo } from 'react';


type Props = Partial<CardProps> & {
  store: FetchedStore;
  isArchived: boolean;
  getStores: () => void;
  firstItem?: React.ReactNode;
  secondItem?: React.ReactNode;
  hiddenButtons?: StoreActionButtonEnums[];
};

export default function StoreCard(props: Props) {
  const { store, firstItem, secondItem, isSelected } = props;
  const storeActionPerm = useCheckPermission(Permissions.ADMIN_STORE_ACTION);
  const { t } = useTranslation();

  const infoLabels = useMemo(() => {
    const results: CardInfoProps[] = []
    if(store.HasDismissPhoto){
      results.push({title: t('unloadedPhotoExists'), icon: <InfoOutlined />})
    }
    if(store.ArchivedBy){
        results.push({
          title:(
            <>
                <p>{`Arşivleyen:  ${store.ArchivedBy}`}</p>
                <p>{t('date') + ': ' + convertApiDate(store.ArchivedDate ?? '')}</p>
            </>),
          icon:<FolderOpenOutlined />})
    }
    if(store.WifiConfiguration?.SSID){
      results.push({title: store.WifiConfiguration?.SSID, icon: <WifiOutlined />})
    }
    return results;
  }, [t, store.ArchivedBy, store.ArchivedDate, store.WifiConfiguration?.SSID, store.HasDismissPhoto])

  return (
    <Card
      key={store.StoreId}
      activeBorders={isSelected}
      disableDetail
      className="store-card"
      hideActionButtons={!storeActionPerm}
      cardInfoItems={infoLabels}
      content={{
        firsItem: {
          custom: firstItem ?? (
            <HierarchyItems
              brandId={store?.BrandId}
              storeId={store.StoreId}
              brand={store?.BrandName}
              store={store.StoreName}
              textWidth="20ch"
            />
          ),
        },
        secondItem: {
          custom: secondItem ?? (
            <PassiveCount
              itemCount={store.BoardCount}
              passiveItemCount={store.PassiveBoardCount}
              activeItemTitle={t('onBoards')}
              passiveItemTitle={t('offBoards')}
            />
          ),
        },
      }}
      extraButtons={<StoreActionButtons {...props} />}
      onClick={props.onClick}
    />
  );
}
