import {
  Board,
  BoardId,
  EditedBoard,
  FetchedBoardsResponse,
  GetBoardsParams,
} from '@Types/Board';
import { CameraId, FetchedCamerasResponse } from '@Types/Camera';
import { PaginationParams } from '@Types/Filter';
import model from './index';
import {
  NetworkConfigirationData,
  WifiConfigiration,
} from '@Types/WifiConfigiration';
import { StaticIp, StaticIpData } from '@Types/StaticIp';

class BoardsService extends model {
  constructor() {
    super();
    this.Prefix('Boards');
  }

  /**
   * @param {BoardId} boardId
   * @description Archive board
   * @returns Promise
   */
  async ArchiveBoard(boardId: BoardId) {
    return this.PrefixId(boardId).Post('Archive').offset().Save();
  }

  /**
   * @param {BoardId} boardId
   * @description Unarchive board
   * @returns Promise
   */
  async UnarchiveBoard(boardId: BoardId) {
    return this.PrefixId(boardId).Post('UnArchive').offset().Save();
  }

  /**
   * @param {BoardId} boardId
   * @param {EditedBoard} board
   * @description Edit board
   * @returns Promise
   */
  async EditBoard(boardId: BoardId, board: EditedBoard) {
    return this.PrefixId(boardId).Put().Data(board).Save();
  }

  /**
   * @param {BoardId} boardId
   * @description Delete board
   * @returns Promise
   */
  async DeleteBoard(boardId: BoardId) {
    return this.PrefixId(boardId).Delete().Save();
  }

  /**
   * @param {BoardId} boardId
   * @description Get board detail
   * @returns Promise
   */
  async GetBoardDetail(boardId: BoardId) {
    return this.PrefixId(boardId).Get().offset().Save<Board>();
  }

  /**
   * @param {PaginationParams} params
   * @description Get all available unarchived boards
   * @returns Promise
   */
  async GetAllUnarchived(params: GetBoardsParams) {
    return this.Post('AllUnArchived')
      .Data(params.filters)
      .offset()
      .Save<FetchedBoardsResponse>();
  }

  /**
   * @param {GetBoardsParams} params
   * @description Get all available archived boards
   * @returns Promise
   */
  async GetAllArchived(params: GetBoardsParams) {
    return this.Post('AllArchived')
      .Data(params.filters)
      .offset()
      .Save<FetchedBoardsResponse>();
  }

  /**
   * @param {PaginationParams} params
   * @param {BoardId} boardId
   * @description Get archived cameras
   * @returns Promise
   */
  async GetArchivedCameras(params: PaginationParams, boardId: BoardId) {
    return this.PrefixId(boardId)
      .Post('ArchivedCameras')
      .Data(params)
      .offset()
      .Save<FetchedCamerasResponse>();
  }

  /**
   * @param {PaginationParams} params
   * @param {BoardId} boardId
   * @description Get unarchived cameras
   * @returns Promise
   */
  async GetUnArchivedCameras(params: PaginationParams, boardId: BoardId) {
    return this.PrefixId(boardId)
      .Post('UnArchivedCameras')
      .Data(params)
      .offset()
      .Save<FetchedCamerasResponse>();
  }

  /**
   * @param {CameraId} cameraId
   * @param {BoardId} boardId
   * @description Take screenshot of a camera
   * @returns Promise
   */
  async TakeScreenshot(boardId: BoardId, cameraId: CameraId) {
    return this.PrefixId(boardId).Post(`Cameras/${cameraId}/Shot`).Save();
  }

  /**
   * @param {BoardId} boardId
   * @description Ping a board
   * @returns Promise
   */
  async Ping(boardId: BoardId) {
    return this.PrefixId(boardId).Post('Ping').Save();
  }

  /**
   * @param {BoardId} boardId
   * @param {number} port
   * @description Open SSH tunnel to a board
   * @returns Promise
   */
  async SSHTunnel(boardId: BoardId, port: number) {
    return this.PrefixId(boardId).Post(`SSHTunnel/${port}`).Save();
  }

  /**
   *
   * @param {number} boardId
   * @param {WifiConfigiration} BoardWifiConfig
   * @description Board Wifi Configiration Add or Edit
   * @returns Promise
   *    */
  async PostBoardWifiConfiguration(
    BoardWifiConfig: WifiConfigiration,
    boardId?: number
  ) {
    if (!boardId) {
      return Promise.reject('boardId  is Required!');
    }
    return this.PrefixId(boardId)
      .Post(`WifiConfiguration`)
      .Data(BoardWifiConfig)
      .Save();
  }

  /**
   *
   * @param {number} boardId
   * @returns {NetworkConfigirationData}
   */
  async GetBoardNetworkConfiguration(boardId?: number) {
    if (!boardId) {
      return Promise.reject('boardId  is Required!');
    }
    return this.PrefixId(boardId)
      .Get(`NetworkConfiguration`)
      .Save<NetworkConfigirationData>();
  }

  /**
   *
   * @param {number} boardId
   * @param {number} configurationId
   * @description Delete a wifi configiration.
   * @returns Promise
   */
  async DeleteWifiConfiguration(boardId: number, configurationId: number) {
    return this.PrefixId(boardId)
      .Delete(`WifiConfiguration/${configurationId}`)
      .Save<any>();
  }

  /**
   *
   * @param {number} boardId
   * @param {StaticIp} BoardStaticIpConfig
   * @description Board Static Ip Configiration Add or Edit
   * @returns Promise
   *    */
  async PostBoardIPConfiguration(
    BoardStaticIpConfig: StaticIp,
    boardId?: number
  ) {
    if (!boardId) {
      return Promise.reject('boardId  is Required!');
    }
    return this.PrefixId(boardId)
      .Post(`IPConfiguration`)
      .Data(BoardStaticIpConfig)
      .Save();
  }

  /**
   *
   * @param {number} boardId
   * @returns {NetworkConfigirationData}
   */
  async GetBoardIPConfiguration(boardId?: number) {
    if (!boardId) {
      return Promise.reject('boardId  is Required!');
    }
    return this.PrefixId(boardId)
      .Get(`IPConfiguration`)
      .Save<NetworkConfigirationData>();
  }

  /**
   *
   * @param {number} boardId
   * @param {number} configurationId
   * @description Delete a ip configiration.
   * @returns Promise
   */
  async DeleteIPConfiguration(boardId: number, configurationId: number) {
    return this.PrefixId(boardId)
      .Delete(`IPConfiguration/${configurationId}`)
      .Save<any>();
  }

  /**
   * Tag a board as a receiver
   */
  async TagAsReceiver(boardId: BoardId) {
    return this.PrefixId(boardId).Post('Receiver').Save();
  }

  /**
   * Delete a board that was tagged as a receiver
   */
  async RemoveTagAsReceiver(boardId: BoardId) {
    return this.PrefixId(boardId).Delete('Receiver').Save();
  }

  async SetActiveFiles(boardId: BoardId) {
    return this.PrefixId(boardId).Put('SetActiveFiles').Save();
  }
}

export default BoardsService;
