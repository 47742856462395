import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GET_ALL_VERSIONS,
  GET_MAIN_FILE,
  GET_CONTROL_FILE,
  GET_MODEL_FILE,
  GET_BEACON_FILE,
  GET_CODE_VERSION,
  GET_PACKAGE_FILES,
  GET_BLE_FILES,
  RESET_CODE_VERS,
  GET_VERSION_FILE_GROUPS,
  GET_MAIN_FILE_GROUPS,
  GET_CONTROL_FILE_GROUPS,
  GET_BEACON_FILE_GROUPS,
  GET_MODEL_FILE_GROUPS,
} from './type';
import VersionService from '@Services/Api/Version';
import {
  AllVersion,
  GetVersionFileGroupsParams,
  GetVersionParams,
} from '@Types/Version';
import AdminService from '@Services/Api/Admin';
import { VersionFile } from '@Enums/VersionFileEnums';

export const getMainGroups = createAsyncThunk(
  GET_MAIN_FILE_GROUPS,
  async () => {
    try {
      const versionFileGroups = await new VersionService().GetVersionFileGroups(
        { type: VersionFile.MAIN }
      );
      return versionFileGroups;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getControlGroups = createAsyncThunk(
  GET_CONTROL_FILE_GROUPS,
  async () => {
    try {
      const versionFileGroups = await new VersionService().GetVersionFileGroups(
        { type: VersionFile.CONTROL }
      );
      return versionFileGroups;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getBeaconGroups = createAsyncThunk(
  GET_BEACON_FILE_GROUPS,
  async () => {
    try {
      const versionFileGroups = await new VersionService().GetVersionFileGroups(
        { type: VersionFile.BEACON }
      );
      return versionFileGroups;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getModelGroups = createAsyncThunk(
  GET_MODEL_FILE_GROUPS,
  async () => {
    try {
      const versionFileGroups = await new VersionService().GetVersionFileGroups(
        { type: VersionFile.MODEL }
      );
      return versionFileGroups;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getVersionFileGroups = createAsyncThunk(
  GET_VERSION_FILE_GROUPS,
  async (params: GetVersionFileGroupsParams) => {
    try {
      const versionFileGroups = await new VersionService().GetVersionFileGroups(
        params
      );
      return versionFileGroups;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getAllVersions = createAsyncThunk(
  GET_ALL_VERSIONS,
  async (params: GetVersionParams) => {
    try {
      const allVersions = await new VersionService().GetAllVersions(params);
      return allVersions;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
export const getMainFile = createAsyncThunk(
  GET_MAIN_FILE,
  async (all: boolean) => {
    try {
      const file = await new AdminService().GetAdminVersion(
        VersionFile.MAIN,
        all
      );
      return file;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
export const getControlFile = createAsyncThunk(
  GET_CONTROL_FILE,
  async (all: boolean) => {
    try {
      const file = await new AdminService().GetAdminVersion(
        VersionFile.CONTROL,
        all
      );
      return file;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
export const getModelFile = createAsyncThunk(
  GET_MODEL_FILE,
  async (all: boolean) => {
    try {
      const file = await new AdminService().GetAdminVersion(
        VersionFile.MODEL,
        all
      );
      return file;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
export const getBeaconFile = createAsyncThunk(
  GET_BEACON_FILE,
  async (all: boolean) => {
    try {
      const file = await new AdminService().GetAdminVersion(
        VersionFile.BEACON,
        all
      );
      return file;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
export const getCodeVersionsById = createAsyncThunk(
  GET_CODE_VERSION,
  async (versionId: number) => {
    try {
      const version = await new VersionService().GetCodeVersionsById(versionId);
      return version;
    } catch (err) {
      console.error(err);
      return {} as AllVersion;
    }
  }
);

export const resetCodeVersions = createAction(RESET_CODE_VERS);

export const getPackageFiles = createAsyncThunk(
  GET_PACKAGE_FILES,
  async (all: boolean) => {
    try {
      const file = await new AdminService().GetAdminVersion(
        VersionFile.PACKAGE,
        all
      );
      return file;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getBleFiles = createAsyncThunk(
  GET_BLE_FILES,
  async (all: boolean) => {
    try {
      const file = await new AdminService().GetAdminVersion(
        VersionFile.BLE,
        all
      );
      return file;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
