import StatusBadge from '@Components/StatusBadge';
import { FetchedBoard } from '@Types/Board';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import './style.scss';
import { Divider, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

interface IProps {
  board: FetchedBoard;
}

export function BoardStatusInstalledIndicator(props: IProps) {
  const { board } = props;
  const { t } = useTranslation();

  return (
    <div className="board-status-installed-indicator">
      <StatusBadge isOn={board.Status} />
      <Divider type="vertical" style={{ marginInline: 4 }} />
      {board.Installed ? (
        <Tooltip title={t('hadInstalled')}>
          <CheckCircleOutlined style={{ color: '#28B463' }} />
        </Tooltip>
      ) : (
        <Tooltip title={t('notHadInstalled')}>
          <CloseCircleOutlined style={{ color: '#909497' }} />
        </Tooltip>
      )}
    </div>
  );
}

function Label() {
  const { t } = useTranslation();
  return (
    <div>
      {t('status')}
      <Divider type="vertical" style={{ marginInline: 4 }} />
      {t('installed')}
    </div>
  );
}

BoardStatusInstalledIndicator.Label = Label;
